<!--
 * @Author: wxy
 * @Date: 2022-09-26 10:13:51
 * @LastEditTime: 2022-09-26 10:14:02
 * @Description: 已发起工单-服务记录-查看附件
-->
<template>
  <div>服务记录查看附件</div>
</template>

<script>
export default {};
</script>

<style>

</style>